<template>

  <div class="row">
    <div 
      class="col-12 col-md-6 col-lg-4"
      v-for="(video, i) in videos" 
      :key="i"
    >
    <article class="card card-item card-shadow border-round-10 border-0 mb-3">
          <figure class="card-item-figure">
              <div class="card-item-config">
                <a 
                  href="javascript:" 
                  @click="openPrev(true), (video_selected = video)"
                  data-bs-toggle="modal" 
                  data-bs-target="#video-detail"
                  class="card-item-config-btn"
                  content="Ver video" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="eye"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="editVideo(video)"
                  class="card-item-config-btn"
                  content="Editar video" 
                  v-tippy="{ arrow: true }"
                >
                  <font-awesome-icon icon="pen"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="openQuestionDelete(video)"
                  class="card-item-config-btn"
                  content="Eliminar video"
                  v-tippy="{ arrow: true }" 
                >
                  <font-awesome-icon icon="trash-alt"/>
                </a>
                <a 
                  href="javascript:" 
                  @click="changeStateVideo(video)"
                  class="card-item-config-btn"
                  :content="(video.id_estado_fk == 1 ? 'Desactivar' : 'Activar') + ' video' "
                  v-tippy="{ arrow: true }" 
                >
                  <font-awesome-icon :icon="video.id_estado_fk == 1 ? 'ban' : 'check'"/>
                </a>
              </div>
              <div 
                class="card-item-video" 
                v-if="video.id_estado_fk == 1" 
              >
                <div class="card-item-video-play">
                  <font-awesome-icon 
                    class="card-item-video-play-icon" 
                    icon="play"
                  />
                </div>
              </div>
              <div 
                class="card-item-future"
                v-if="video.id_estado_fk == 3" 
              >
              <h5 class="mb-0 text-center text-white">
                <strong>Video desactivado</strong>
              </h5>
              </div>
              <span class="card-item-date">
                {{ dateFormatymd(video.fecha_video) }}
              </span>
              <img 
                :src="video.url_imagen"
                class="card-item-figure-img"
              >
          </figure>
          <div class="card-body card-item-excerpt">
            <h5 class="card-item-title-1 font-main color-gray">
              <font-awesome-icon icon="video" class="color-main pe-1"/>
              {{ video.titulo_video }}
            </h5>
            <div class="card-item-data">
              <span>
                <i 
                v-if="handleLikeIconClass(video.likes)"
                class="fa-solid fa-heart color-rose-shadow">
              </i>
              <i 
                v-else
                class="fa-regular fa-heart color-main">
              </i>
                {{ video.likes }}
                Me gusta
              </span>
            </div>
          </div>
        </article>

        <div class="row" v-if="videos.length == 0">
          <div class="col-12">
            <h3 class="news-list__sub-title text-start mt-3">
              No exiten resultados con este filtro
            </h3>
          </div>
        </div>

        <Spinner v-if="show_spinner"></Spinner>
        <Preview 
          :obj="video_selected" 
          @back="back"
        />
        <Status
          v-if="open_modal_status"
          :msg="modal_status_msg"
          :status="modal_status"
          @close="closeStatus"
        />
        <Question
          v-if="open_question_modal"
          :msg="question_modal_msg"
          :hideCancel="false"
          @cancel="cancelQuestion"
          @accept="acceptQuestion"
        />

    </div>
  </div>

</template>

<script>
import Question from "../Modales/Question.vue";
//import HeartBorder from "../SVG/HeartBorder.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
import { mapActions } from "vuex";
import Preview from "./ModalVideo.vue";

export default {
  components: {
    //HeartBorder,
    Preview,
    Status,
    Spinner,
    Question,
  },

  props: {
    videos: {
      type: Array,
      required: true,
    },
    open_preview: {
      type: Boolean,
      required: true,
    },
  },
  
  data() {
    return {
      open_modal_status: false,
      show_spinner: false,
      video_to_delete: null,
      open_question_modal: false,
      question_modal_msg: "",
      modal_status_msg: "",
      modal_status: true,
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      video_selected: {},
    };
  },

  methods: {
    ...mapActions("videoModule", ["addVideoAction", "changeVideoState", "deleteVideo"]),

    closeStatus() {
      this.open_modal_status = false;
    },

    openPrev(state) {
      this.$emit("editOpenPrev", state);
    },

    back() {
      this.openPrev(false);
      this.video_selected = {};
    },

    async changeStateVideo(obj) {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        idVideo: obj.id_video,
        idEstado: obj.id_estado_fk == "1" ? "3" : "1",
        alertaPush: "0",
      };
      const new_state_video = await this.changeVideoState(payload);
      if (new_state_video) {
        this.toastStatusChangeSuccess();
      } else {
        this.toastStatusChangeError();
      }
      this.show_spinner = false;
    },

    async acceptQuestion() {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        idUsuario: this.id_usuario,
        idVideo: this.video_to_delete.id_video,
        idEstado: "1",
        alertaPush: "0",
      };
      const deleteVideoRes = await this.deleteVideo(payload);
      if (!deleteVideoRes) {
        this.toastVideoDeleteFalla();
      } else {
        this.toastVideoDeleteExitoso();
        this.$emit("videoDeleted");
      }
      this.open_question_modal = false;
      this.show_spinner = false;
    },

    openQuestionDelete(doc) {
      this.video_to_delete = doc;
      this.open_question_modal = true;
      this.question_modal_msg = "¿Estas seguro de eliminar este video?";
    },

    cancelQuestion() {
      this.open_question_modal = false;
    },

    editVideo(video) {
      this.$router
        .push({
          name: "videos-edit",
          params: { id: video.id_video },
        })
        .catch(() => {
          return;
        });
    },

    handleLikeIconClass(likes) {
      if (likes > 0 && likes !== null) {
        return true;
        // return "active";
      } else {
        return false;
      }
    },

    toastStatusChangeSuccess() {
      this.$toast.open({
        message: "Se ha cambiado el estado correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastStatusChangeError() {
      this.$toast.open({
        message: "Ocurrió un error al cambiar el estado del video. Por favor inténtalo nuevamente.",
        type: "error",
        position: "top-right",
        duration: 5000,
      });
    },
    toastVideoDeleteFalla() {
			this.$toast.open({
				message: "Hubo errores al momento de eliminar video. Por favor inténtalo nuevamente",
				type: "error",
				duration: 6000,
				position: "top-right",
			});
    },
    toastVideoDeleteExitoso() {
			this.$toast.open({
				message: "El video ha sido eliminado exitosamente.",
				type: "success",
				duration: 6000,
				position: "top-right",
			});
    }
  },
};
</script>

<style></style>
