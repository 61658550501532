<template>
  <div 
    class="modal modal-custom fade" 
    tabindex="-1" 
    aria-hidden="true"
    id="video-detail">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">
            <font-awesome-icon icon="video" class="color-main pe-2"/>
            <span>{{ obj.titulo_video }}</span>
          </h1>
          <button 
            type="button" 
            class="modal-custom-close"
            data-bs-dismiss="modal" 
            aria-label="Close">
            <font-awesome-icon icon="xmark"/>
          </button>
        </div>
        <div class="modal-body">

          <div class="row">
            <div class="col-12">
              <video 
                controls 
                class="mb-3"
                :src="obj.url_video" 
                :poster="obj.url_imagen"
              />
              <h4 class="modal-custom-title">
                {{dateFormatymd(obj.fecha_video)}}
              </h4>
              <div 
                class="modal-custom-text">
                {{ obj.descripcion_video }}
              </div>
            </div>
          </div>  
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            class="btn btn-custom btn-custom-color-blue border-round-50" 
            @click="editVideo(obj)"
            data-bs-dismiss="modal"
          >
            <font-awesome-icon icon="pen" class="pe-2"/>
            Editar
          </button>
        </div>
      </div>
    </div>
  </div>

<!--   <div class="video-modal">
    <div class="video-modal__container">
      <button @click="back" class="video-modal__back">
        <font-awesome-icon icon="times"></font-awesome-icon>
      </button>
      <div class="video-modal__header">
       <div class="video-modal__header-perfil">
          
          <div class="video-modal__header-perfil-name dark-font">
            {{ obj.titulo_video }}
          </div>
        </div>
        <div class="video-modal__header-actions">
          <font-awesome-icon icon="pen" @click="edit(obj)"></font-awesome-icon>
          <img
            class="video-modal__header-action"
            src="@/assets/img/qiyab.png"
            alt="dots"
          />
          <font-awesome-icon
            icon="trash-alt"
            @click="delete_wall"
          ></font-awesome-icon>
        </div>
      </div>
        <div class="video-modal__categorie">
        {{obj.categoria[0].nombre_categoria}}
        </div>
      <div class="video-modal__main">
        <video class="video-modal__main-video" :src="obj.url_video" controls alt="video preview" />
        <div class="video-modal__main-text">{{ obj.descripcion_video }}</div>
        <div class="video-modal__main-activity">
          <div class="video-modal__main-coments">
            <img
              class="video-modal__main-coments-img"
              src="@/assets/img/utilities/chat.png"
              alt=""
            />
            <div class="video-modal__main-coments-num">
              {{
                obj.comentarios_compartidos == null
                  ? "0"
                  : obj.comentarios_compartidos.length
              }}+
            </div>
          </div>
          <div class="video-modal__main-likes">
            <img
              class="video-modal__main-likes-img"
              src="@/assets/img/utilities/heart.svg"
              alt=""
            />
            <div class="video-modal__main-likes-num">
              {{ obj.likes.length }}+
            </div>
          </div>
        </div>
        <hr />
        <div
          v-for="(coment, i) in obj.comentarios_compartidos"
          :key="i"
          class="video-modal__main-comment"
        >
          <div class="video-modal__main-comment-text">
            <p class="video-modal__main-comment-text-p">
              <strong
                >{{ coment.usuario[0].primer_nombre }}
                {{ coment.usuario[0].apellido_paterno }}:</strong
              >
              {{ coment.texto }}
            </p>
            <div class="video-modal__main-comment-activity">
              <div class="video-modal__main-likes">
                <img
                  class="video-modal__main-likes-img"
                  src="@/assets/img/utilities/heart.svg"
                  alt=""
                />
                <div class="video-modal__main-likes-num">
                  {{ coment.likes.length }}+
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-modal__date ">
        <div class="video-modal__date-box">
        {{dateFormatymd(obj.fecha_video)}}

        </div>
        </div>
    </div>
  </div> -->
</template>

<script>
export default {
  props: {
    obj: { require: true },
  },
  data() {
    return {
      user_default_img: require("@/assets/img/user_default_img.svg"),
    };
  },
  methods: {
    handleUserImage(img) {
      if (img !== null && img !== "" && img !== undefined) {
        return img;
      } else {
        return this.user_default_img;
      }
    },
    back() {
      this.$emit("back");
    },
    edit(obj) {
      console.log("en modal", obj);
      this.$emit("editWall", obj);
    },
    editVideo(video) {
      this.$router
        .push({
          name: "videos-edit",
          params: { id: video.id_video },
        })
        .catch(() => {
          return;
        });
    },
    delete_wall() {
      this.$emit("deleteWall", this.obj);
    },
  },
};
</script>

<style></style>
