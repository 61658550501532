<template>

  <section id="gallery-video">

    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <i class="fa-solid fa-video color-secondary"></i>
          {{ subtitle }}
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/videos/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Subir video
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-6 col-lg-7 input-search">
            <input 
                type="search" 
                class="form-control input-custom"
                placeholder="Buscar video" 
                v-model.trim="title" 
                @keypress.enter="getPaginatedVideoIds()">
                <span class="input-search-icon" @click="getPaginatedVideoIds()">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col-6 col-lg-5">
            <select class="videoadd-section2__input-border" v-model="cat_selected">
              <option value="" disabled>Selecciona una categoría</option>
              <option value="Todas">Todas</option>
              <option :value="cat.id_categoria" v-for="cat in categorias" :key="cat.id_categoria">
                {{ cat.nombre_categoria }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
              icon="filter" 
              class="color-secondary pe-2"
            />
            Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Spinner v-if="show_spinner"/>
    <div class="row">
      <div class="col-12">
        <VideoCard 
          :videos="lista_videos" 
          :open_preview="open_preview" 
          @editOpenPrev="editOpenPrev"
          @videoDeleted="onVideoDelete"
        />
      </div>
    </div>

    <!-- pagination -->
    <div class="users-list__pagination" v-if="pagination.page_count > 0">
      <paginate
        v-model="pagination.actual_page"
        :page-count="videoVideosPagination.length"
        :page-range="pagination.page_range"
        :click-handler="pagination.click_handler"
        :prev-text="pagination.prev_text"
        :next-text="pagination.next_text"
        :container-class="pagination.container_class"
        :page-class="pagination.page_class"
      >
      </paginate>
    </div>
    <!-- Modal para eliminar video -->
    <Delete
      v-if="open_modal_delete"
      title="Eliminar video"
      msg="¿Estás seguro que deseas eliminar este video?"
      @cancel="cancelDelete"
      @delete="deleteVideo"
    />
  </section>

</template>

<script>
import Delete from "../Modales/Delete.vue";
import Spinner from "../Spinner.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import VideoCard from "./VideoCard.vue";

export default {
  components: { Delete, VideoCard, Spinner },

  data() {
    return {
      subtitle:
        "Conoce todos los videos que están publicados en LinkiWork. Podrás editar sus descripciones o eliminarlos si lo requieres.",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.onPageChange,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      open_preview: false,
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      max_date: "",
      min_date: "",
      calendar_side: "",
      id_usuario: this.$ls.get("user").id_usuario,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      open_modal_delete: false,
      show_modal_view: false,
      video_selected: {},
      show_modal: false,
      show_spinner: false,
      categorias: [],
      lista_videos: [],
      show_calendar: false,
      filesPerPage: "10",
      title: "",
      cat_selected: ''
    };
  },

  computed: {
    ...mapState("videoModule", ["videoVideosPagination"]),
    ...mapGetters("videoModule", ["videoVideosFiltered", "paginationDataForVideoVideos"]),
    
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    }
  },

  watch: {
    cat_selected() {
      this.getPaginatedVideoIds();
    }
  },

  async mounted() {
    await this.getCategories();
    await this.getAllVideos();
    await this.getPaginatedVideoIds();
  },

  methods: {
    ...mapActions("videoModule", ["addVideoAction", "getPagination", "getAllVideoVideos", "getCategoriesAction"]),
   
    async getAllVideos() {
      const data = {
        idEmpresa: this.id_empresa,
        limite: this.filesPerPage,
        idCategoria: this.cat_selected == "Todas" || this.cat_selected == "" ? "0" : this.cat_selected,
        fechaInicio: this.init_date=="Inicio"?"":this.init_date,
        fechaFinal: this.end_date=="Termino"?"": this.end_date,
      };

      const res = await this.getAllVideoVideos(data);
      if (!res) {
        this.toastGetVideosError();
      }
    },

    editOpenPrev(state) {
      this.open_preview = state;
    },

    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.init_date = day.id;
      } else {
        this.end_date = day.id;
      }
      if (this.init_date != "Inicio" && this.end_date != "Término") {
        this.getPaginatedVideoIds();
      }
      this.show_calendar = false;
    },

    deleteDate() {
      this.init_date = "Inicio";
      this.end_date = "Término";
      this.getPaginatedVideoIds();
      this.show_calendar = false;
    },

    //mostrar modal de video
    showVideo(video) {
      this.show_modal_view = true;
      this.video_selected = video;
    },

    //cancelar eliminacion
    cancelDelete() {
      this.open_modal_delete = false;
    },

    handleCalendar() {
      this.show_calendar = false;
    },

    clearDates(){
      this.init_date = 'Inicio';
      this.end_date = 'Término';
      this.date_start = '';
      this.date_end = '';
      this.getPaginatedVideoIds();
      this.show_calendar = false;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        if (this.end_calendar == true) {
          this.max_date = this.date_end;
        }
      }
      if (this.calendar_side == "end-date") {
        this.max_date = "";
        if (this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },

    async getCategories() {
      const payload = {
        idEmpresa: this.id_empresa,
        plataforma: "2",
      };

      this.categorias = await this.getCategoriesAction(payload);
      if (this.categorias === null) {
        this.toastGetVideosError();
      } 
    },

    async onPageChange(page) {
      this.show_spinner = true;
      this.pagination.actual_page = page;
      await this.getVideoVideosForPage(page);
      this.show_spinner = false;
    },

    async getPaginatedVideoIds(nombre_video="") {
      this.show_spinner = true;
      const payload = {
        idEmpresa: this.id_empresa,
        nombre: nombre_video,
        fechaInicio: this.init_date == "Inicio" || this.end_date == "Término" ? "" : this.init_date,
        fechaFinal: this.init_date == "Inicio" || this.end_date == "Término" ? "" : this.end_date,
        idCategoria: this.cat_selected == "Todas" || this.cat_selected == "" ? "0" : this.cat_selected,
        limite: this.filesPerPage
      };
      await this.getPagination(payload);
      if (this.videoVideosPagination === null) {
        this.toastGetVideosError();
      } else {
        if (this.videoVideosPagination.length > 0) {
          this.getVideoVideosForPage(1);
        }
        else{
          this.lista_videos = [];
        }
      }
      this.show_spinner = false;
    },

    async getVideoVideosForPage(page) {
      this.lista_videos = this.videoVideosFiltered(page-1);
    },

    async onVideoDelete() {
      await this.getPaginatedVideoIds();
      this.pagination.actual_page = 1;
    },


  },
};
</script>
