<template>
   <div class="delete-modal">
      <div class="delete-modal-container">
         <div class="delete-modal-container-title">{{ title }}</div>
         <div class="delete-modal-container-message">
            {{ msg }}
         </div>
         <div class="delete-modal-container-buttons">
            <div class="cancel" @click="cancel">Cancelar</div>
            <div class="delete" @click="accept">Eliminar</div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: {
      title: { require: true },
      msg: { require: true },
   },
   methods: {
      cancel() {
         this.$emit("cancel");
      },
      accept() {
         this.$emit("delete");
      },
   },
};
</script>